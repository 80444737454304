import './style.css'
import * as THREE from 'three'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass'
import firefliesVertexShader from './shaders/fireflies/vertex.glsl'
import firefliesFragmentShader from './shaders/fireflies/fragment.glsl'
import backgroundVertexShader from './shaders/backGlow/vertex.glsl'
import backgroundFragmentShader from './shaders/backGlow/fragment.glsl'
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass'
import { FXAAShader } from 'three/addons/shaders/FXAAShader'
import { CustomOrbitControls } from './control/CustomOrbitControl'
// import Stats from 'three/examples/jsm/libs/stats.module'
import { MovementManager } from './movementManager'
// import {OrbitControls} from 'three/addons/controls/OrbitControls'
import { gsap } from 'gsap/all'
import { Vector3 } from 'three'
import { RectAreaLightHelper } from 'three/addons/helpers/RectAreaLightHelper'
import loopify from "./loop";

const gltfLoader = new GLTFLoader()

// const gui = new dat.GUI()
// gui.close()
document.getElementById('topcat_year').innerText =
  '2022-' + new Date().getFullYear()
const canvas = document.querySelector('canvas.webgl')

const scene = new THREE.Scene()
let devise = getMobileOperatingSystem()
let second_grid = document.getElementById('aaabbb')
const loading_footer = document.getElementById("loading_footer")

scene.background = new THREE.Color(0x000007)
// scene.fog = new THREE.Fog(0x020126, 1, 15)
let backgroundGeometry = new THREE.PlaneGeometry(20, 20, 10, 10)
let backgroundMaterial = new THREE.ShaderMaterial({
  vertexShader: backgroundVertexShader,
  fragmentShader: backgroundFragmentShader,
  uniforms: {
    uTime: { value: 0 },
    uColor: { value: new THREE.Vector3(24, 6, 145) },
    uK: { value: 0 },
    uAlt: {value:0}
  },
  transparent: true,
  depthWrite: false,
  side: THREE.DoubleSide,
})
let backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial)
backgroundMesh.visible = false
scene.add(backgroundMesh)

let startAnimation = false
let finish = false
const movementManager = new MovementManager()
let ethereumMaterial = undefined
gltfLoader.load('/models/Scene14.glb', (gltf) => {
  movementManager.setGLTF(gltf, canvas, camera, backgroundMesh, fireflies)
  console.log(gltf.scene)

  gltf.scene.traverse((child) => {
    if (
      (child.isMesh && child.name === 'Cat_planet_2') ||
      child.name === 'Cat_planet_3'
    ) {
      child.material = new THREE.MeshBasicMaterial({
        color: 0x00af00,
        side: THREE.DoubleSide,
      })
    }
    if (child.isMesh && child.material.name === 'Neon') {
      console.log('mm', child.material.name)
      let textureLoader = new THREE.TextureLoader()
      let texture = textureLoader.load('MatcapMaterial/13.png')
      child.material = new THREE.MeshMatcapMaterial({ matcap: texture })
    }
    if (child.isMesh && child.material.name === 'Neon.003') {
      console.log('mm', child.material.name)
      ethereumMaterial = child.material
      let textureLoader = new THREE.TextureLoader()
      let texture = textureLoader.load('MatcapMaterial/13.png')
      child.material = new THREE.MeshMatcapMaterial({ matcap: texture })
    }
    child.castShadow = true
    child.receiveShadow = true
  })

  scene.add(gltf.scene)
  // updateAllMaterials()
})

let createAreaLight = (color, intensity, position, size, lookAt) => {
  const areaLight = new THREE.RectAreaLight(color, intensity)
  areaLight.position.copy(position)
  areaLight.width = size.x
  areaLight.height = size.y
  areaLight.lookAt(lookAt)
  scene.add(areaLight)
  // scene.add(areaLight.target)
  // let helper = new RectAreaLightHelper(areaLight)
  // scene.add(helper)
  return areaLight
}

// top
let topLight = createAreaLight(
  '#490303',
  500,
  new THREE.Vector3(0, 6, 0),
  new THREE.Vector2(1, 1),
  new THREE.Vector3(0, 0, 0),
)
// left
createAreaLight(
  '#300333',
  100,
  new THREE.Vector3(6, 0, -1),
  new THREE.Vector2(2, 1),
  new THREE.Vector3(0, 0, 0),
)
// right
createAreaLight(
  '#300333',
  100,
  new THREE.Vector3(-6, 0, -1),
  new THREE.Vector2(2, 1),
  new THREE.Vector3(0, 0, 0),
)
// bottom
let bottom = createAreaLight(
  '#380047',
  280,
  new THREE.Vector3(0, -5, 0),
  new THREE.Vector2(1, 1),
  new THREE.Vector3(0, 0, 0),
)
// back
let back = createAreaLight(
  '#eb0000',
  20,
  new THREE.Vector3(0, -4, -6),
  new THREE.Vector2(1, 1),
  new THREE.Vector3(0, 0, 0),
)
// front
let front = createAreaLight(
  '#600606',
  40,
  new THREE.Vector3(0, 0, 8),
  new THREE.Vector2(1, 1),
  new THREE.Vector3(0, 0, 0),
)

let front2 = createAreaLight(
  '#600606',
  100,
  new THREE.Vector3(-5.5, -1, 5.5),
  new THREE.Vector2(1, 1),
  new THREE.Vector3(0, 0, 0),
)

let ambientLight = new THREE.AmbientLight('#300333', 0.3)
scene.add(ambientLight)

const firefliesGeometry = new THREE.BufferGeometry()
const firefliesCount = 400
const positionArray = new Float32Array(firefliesCount * 3)
const scaleArray = new Float32Array(firefliesCount)
const periodArray = new Float32Array(firefliesCount)

const firefliesGeometry2 = new THREE.BufferGeometry()
const firefliesCount2 = 30000
const positionArray2 = new Float32Array(firefliesCount2 * 3)
const scaleArray2 = new Float32Array(firefliesCount2)
const periodArray2 = new Float32Array(firefliesCount2)

for (let i = 0; i < firefliesCount2; i++) {
  let i1 = Math.random() - 0.5
  let i2 = Math.random() - 0.5
  let i3 = (Math.random() - 0.5) * 20 + 1

  let x = Math.sin(i1 * Math.PI * 2) * Math.cos(i2 * Math.PI * 2) * i3
  let z = Math.sin(i1 * Math.PI * 2) * Math.sin(i2 * Math.PI * 2) * i3
  let y = Math.cos(i1 * Math.PI * 2) * i3

  positionArray2[i * 3 + 0] = x * (Math.random() > 0.5 ? 1 : -1)
  positionArray2[i * 3 + 1] = y * (Math.random() > 0.5 ? 1 : -1)
  positionArray2[i * 3 + 2] = z * (Math.random() > 0.5 ? 1 : -1)
  let scale = Math.random() * 5
  scaleArray2[i] = scale
  periodArray2[i] = Math.random()
  // distanceArray[i] = new Vector3(x, y, z).distanceTo(new Vector3(0, 0, 0)) / 3
}

firefliesGeometry2.setAttribute(
  'position',
  new THREE.BufferAttribute(positionArray2, 3),
)
firefliesGeometry2.setAttribute(
  'aScale',
  new THREE.BufferAttribute(scaleArray2, 1),
)
firefliesGeometry2.setAttribute(
  'aPeriod',
  new THREE.BufferAttribute(periodArray2, 1),
)

const firefliesMaterial2 = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    uPixelRatio: { value: 4 },
    uSize: { value: 60 },
    uDistanceFromCenter: { value: 40 },
    uPositionScale: { value: 1 },
    uTransparency: { value: 0.1 },
    uDim: { value: 0.8 },
  },
  vertexShader: firefliesVertexShader,
  fragmentShader: firefliesFragmentShader,
  transparent: true,
  blending: THREE.AdditiveBlending,
  depthWrite: false,
})

for (let i = 0; i < firefliesCount; i++) {
  let i1 = Math.random() - 0.5
  let i2 = Math.random() - 0.5
  let i3 = (Math.random() - 0.5) * 20 + 1

  let x = Math.sin(i1 * Math.PI * 2) * Math.cos(i2 * Math.PI * 2) * i3
  let z = Math.sin(i1 * Math.PI * 2) * Math.sin(i2 * Math.PI * 2) * i3
  let y = Math.cos(i1 * Math.PI * 2) * i3

  positionArray[i * 3 + 0] = x * (Math.random() > 0.5 ? 1 : -1)
  positionArray[i * 3 + 1] = y * (Math.random() > 0.5 ? 1 : -1)
  positionArray[i * 3 + 2] = z * (Math.random() > 0.5 ? 1 : -1)
  let scale = Math.random() * 5
  scaleArray[i] = scale
  periodArray[i] = Math.random()
  // distanceArray[i] = new Vector3(x, y, z).distanceTo(new Vector3(0, 0, 0)) / 3
}

firefliesGeometry.setAttribute(
  'position',
  new THREE.BufferAttribute(positionArray, 3),
)
firefliesGeometry.setAttribute(
  'aScale',
  new THREE.BufferAttribute(scaleArray, 1),
)
firefliesGeometry.setAttribute(
  'aPeriod',
  new THREE.BufferAttribute(periodArray, 1),
)

// Material
const firefliesMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTime: { value: 0 },
    uPixelRatio: { value: 1 },
    uSize: { value: 150 },
    uDistanceFromCenter: { value: 0.2 },
    uPositionScale: { value: 1 },
    uTransparency: { value: 0.1 },
    uDim: { value: 0.8 },
  },
  vertexShader: firefliesVertexShader,
  fragmentShader: firefliesFragmentShader,
  transparent: true,
  blending: THREE.AdditiveBlending,
  depthWrite: false,
})
//
// gui
//   .add(firefliesMaterial.uniforms.uSize, 'value')
//   .min(0)
//   .max(1000)
//   .step(1)
//   .name('firefliesSize')

// Points
const fireflies2 = new THREE.Points(firefliesGeometry2, firefliesMaterial2)
scene.add(fireflies2)
const fireflies = new THREE.Points(firefliesGeometry, firefliesMaterial)
scene.add(fireflies)

fireflies.scale.set(1.3, 1.3, 1.3)
/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
}


window.addEventListener('resize', () => {
  console.log(window.innerWidth/window.innerHeight)
  if (window.innerWidth/window.innerHeight < 1) {
    controls.maxDistance = 13
  } else {
    controls.maxDistance = 7
  }
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()
  renderer.setSize(window.innerWidth, window.innerHeight)
  composer.setSize(window.innerWidth, window.innerHeight)
})

// scene.fog = new THREE.FogExp2(0xf000f3, 0.03)
const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.1,
  80,
)
camera.position.set(0, 0, 25)
scene.add(camera)
camera.target = new THREE.Vector3(0, 0, 2)

let scrollDown = () => {
  console.log('scroll down',window.scrollY)
  if (window.scrollY <= 100 && scrollUp) {
    window.scroll({
      top: devise === 'unknown' ? (hOrW?window.innerHeight: window.innerWidth):( hOrW? second_grid.clientHeight: second_grid.clientWidth),
      behavior: 'smooth',
    })
    gsap.to(firefliesMaterial2.uniforms.uDim, {
      value: 0.3,
      duration: 1,
    })
    gsap.to(controls.target, {
      y: -12,
      duration: 1,
    })
    gsap.to(camera.position, {
      x: 0,
      y: -12,
      z: maxDis,
      duration: 1,
    })

    gsap.to(backgroundMesh.material.uniforms.uAlt, {
      value: 7,
      duration: 1
    })
    scrollUp = false
  }
}

const controls = new CustomOrbitControls(camera, canvas)
controls.enablePan = false
// controls.enableZoom = false;
controls.autoRotate = true;
controls.autoRotateSpeed = 0.1
controls.zoomSpeed = 0.115
controls.enableDamping = true
controls.minPolarAngle = 0.8
controls.maxPolarAngle = 2.4
controls.dampingFactor = 0.01
controls.rotateSpeed = 0.4
controls.minDistance = 4
controls.maxDistance = 11.5

if (window.innerWidth/window.innerHeight < 1) {
  controls.maxDistance = 13
} else {
  controls.maxDistance = 7
}

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
})
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.LinearToneMapping
renderer.toneMappingExposure = 1
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.physicallyCorrectLights = true

// "Cat_font" : cat
//
function initPostProcessing() {
  const composer = new EffectComposer(renderer)
  const renderPass = new RenderPass(scene, camera)
  composer.addPass(renderPass)
  let effectFXAA = new ShaderPass(FXAAShader)
  effectFXAA.uniforms['resolution'].value.x = 1 / (window.innerWidth * 2)
  effectFXAA.uniforms['resolution'].value.y = 1 / (window.innerHeight * 2)
  composer.addPass(effectFXAA)
  const bloomPass = new UnrealBloomPass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    0.4,
    0.4,
    0.4,
  )
  composer.addPass(bloomPass)

  return { composer, bloomPass, effectFXAA }
}

const { composer, bloomPass } = initPostProcessing()

// gui.add(renderer, 'toneMapping', {
//   No: THREE.NoToneMapping,
//   Linear: THREE.LinearToneMapping,
//   Reinhard: THREE.ReinhardToneMapping,
//   Cineon: THREE.CineonToneMapping,
//   ACESFilmic: THREE.ACESFilmicToneMapping,
// })
// gui.add(renderer, 'toneMappingExposure').min(0).max(10).step(0.001)
//
// gui.add(bloomPass, 'threshold').min(0).max(1).step(0.001)
// gui.add(bloomPass, 'strength').min(0).max(4).step(0.001)
// gui.add(bloomPass, 'radius').min(0).max(4).step(0.001)
// //
// gui
//   .add(topLight, 'intensity')
//   .min(0)
//   .max(1000)
//   .step(0.001)
//   .name('topLightIntensity')
// gui.addColor(topLight, 'color').name('topLightColor')
// gui.addColor(front, 'color').name('frontColor')
// gui.addColor(back, 'color').name('backColor')
// gui.addColor(bottom, 'color').name('bottomColor')
// let debugObject = {
//   uDistanceFromCenter: 0.2,
//   uPositionScale: 1,
// }
// gui
//   .add(debugObject, 'uDistanceFromCenter')
//   .min(0)
//   .max(10)
//   .step(0.001)
//   .name('fireflies distance')
//   .onChange(() => {
//     firefliesMaterial.uniforms.uDistanceFromCenter.value =
//       debugObject.uDistanceFromCenter
//   })
// gui
//   .add(debugObject, 'uPositionScale')
//   .min(0)
//   .max(10)
//   .step(0.001)
//   .name('fireflies uPositionScale')
//   .onChange(() => {
//     firefliesMaterial.uniforms.uPositionScale.value = debugObject.uPositionScale
//   })
// let cameraFolder = gui.addFolder('camera movement')
//
// cameraFolder
//   .add(controls, 'dampingFactor')
//   .min(0)
//   .max(0.2)
//   .step(0.00001)
//   .name('dampingFactor')
// cameraFolder
//   .add(controls, 'autoRotateSpeed')
//   .min(0)
//   .max(2)
//   .step(0.0001)
//   .name('autoRotateSpeed')
// cameraFolder
//   .add(controls, 'zoomSpeed')
//   .min(0)
//   .max(2)
//   .step(0.0001)
//   .name('zoomSpeed')
// cameraFolder
//   .add(controls, 'minPolarAngle')
//   .min(0)
//   .max(2)
//   .step(0.0001)
//   .name('minPolarAngle')
// cameraFolder
//   .add(controls, 'maxPolarAngle')
//   .min(0)
//   .max(3.14)
//   .step(0.0001)
//   .name('maxPolarAngle')
// cameraFolder
//   .add(controls, 'rotateSpeed')
//   .min(0)
//   .max(2)
//   .step(0.0001)
//   .name('rotateSpeed')
// cameraFolder
//   .add(controls, 'autoRotateSpeed')
//   .min(0)
//   .max(2)
//   .step(0.0001)
//   .name('autoRotateSpeed')
// cameraFolder
//   .add(controls, 'maxDistance')
//   .min(0)
//   .max(60)
//   .step(0.0001)
//   .name('maxDistance')
// cameraFolder
//   .add(controls, 'minDistance')
//   .min(0)
//   .max(60)
//   .step(0.0001)
//   .name('minDistance')
//
// gui
//   .add(movementManager, 'rotationSpeed')
//   .name('rotationSpeed')
//   .min(0)
//   .max(1)
//   .step(0.0001)
// gui
//   .add(movementManager, 'textRotationSpeed')
//   .name('textRotationSpeed')
//   .min(0)
//   .max(2)
//   .step(0.0001)
// fireflies.visible = false
/**
 * Animate
 */
let clock = new THREE.Clock()

// let stats = new Stats()
// stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
// document.body.appendChild(stats.dom)
let changeFireFliesSpeed = false
let firefliesSpeed = 1
let offsetEllapsedTime = 0
let delta = 0
let start = false
let maxDis = 6.5
let cameraPositionClone = camera.position.clone()

let hOrW = second_grid.clientHeight / second_grid.clientWidth > 1

const tick = () => {
  // console.log(firefliesMaterial.uniforms.uDistanceFromCenter.value, firefliesMaterial.uniforms.uPositionScale.value)
  // stats.begin()
  if (second_grid) {
    let hOrWTemp = second_grid.clientHeight / second_grid.clientWidth > 1
    if (hOrWTemp !== hOrW)
      window.location.reload();
  }
  let maniuplateCamera = false
  const elapsedTime = clock.getElapsedTime()

  if (startAnimation && start) {
    controls.enabled = false

    // controls.enabled = false
    startAnimation = movementManager.playAnimation(
      elapsedTime,
      elapsedTime - delta,
    )
    movementManager.updateBefore(elapsedTime, elapsedTime - delta)
    // controls.update()
  } else if (start) {
    controls.enabled = true

    // controls.enabled = true
    finish = true
    startAnimation = false
    fireflies.rotation.y += (elapsedTime - delta) * 0.05
    if (changeFireFliesSpeed === true && firefliesSpeed < 5) {
      firefliesSpeed += 0.05
      console.log('inc', firefliesSpeed)
    } else if (changeFireFliesSpeed === false && firefliesSpeed > 1) {
      firefliesSpeed -= 0.05
      console.log('dec', firefliesSpeed)
    }

    // Update controls
    controls.update()
    movementManager.update(elapsedTime, elapsedTime - delta)
    startAnimation = false
    maxDis = controls.maxDistance - 1
    if (
      scrollUp &&
      camera &&
      controls &&
      controls.target &&
      camera.position.distanceTo(controls.target) > maxDis
    ) {
      maniuplateCamera = true
      let extra = camera.position.distanceTo(controls.target) - maxDis
      console.log(extra / 5)
      cameraPositionClone = camera.position
        .clone()
        .multiplyScalar(0.99 - (extra / 5) * 0.01)
      camera.position.setLength(
        maxDis +
          0.1 *
            (camera.position.distanceTo(
              movementManager.sceneObjects['Planet'].position,
            ) -
              maxDis),
      )
      // console.log(extra/5)
      if (extra / 5 > 0.005) {
        scrollDown()
      }
    }
  }

  backgroundMesh.position.set(
    -camera.position.x,
    -camera.position.y,
    -camera.position.z,
  )
  // console.log(backgroundMesh.material)
  if (movementManager && movementManager.sceneObjects['Planet']) {
    backgroundMesh.lookAt(movementManager.sceneObjects['Planet'].position)
  }

  // console.log("firefliesSpeed", elapsedTime,elapsedTime - delta )

  firefliesMaterial.uniforms.uTime.value +=
    ((elapsedTime - delta) * firefliesSpeed) / 5
  firefliesMaterial2.uniforms.uTime.value +=
    ((elapsedTime - delta) * firefliesSpeed) / 60

  composer.render()

  if (maniuplateCamera) {
    camera.position.set(
      cameraPositionClone.x,
      cameraPositionClone.y,
      cameraPositionClone.z,
    )
  }
  // stats.end()
  delta = elapsedTime

  // Call tick again on the next frame
  window.requestAnimationFrame(tick)
}
console.log("second", second_grid)
tick()

const scrollDownButton = document.getElementById('scroll_button')
const scrollTopButton = document.getElementById('scroll_top')

const soundButton1 = document.getElementById('sound_button1')
const soundButton2 = document.getElementById('sound_button2')

// let clickSound = document.getElementById('click_sound')
// clickSound.volume = 0.1
//
// let backMusic = document.getElementById('background_music')
// backMusic.volume = 0.1

// Detect devise for changes

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone'
  }

  if (/android/i.test(userAgent)) {
    return 'Android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS'
  }
  return 'unknown'
}



if(devise === "iOS") {
    loading_footer.style.bottom = "70px"
}

scrollDownButton.addEventListener('click', () => {
  if (startAnimation) return
  scrollDown()
})

let scrollUp = true

function onMouseWheel(event) {
  event.preventDefault()
  if (window.scrollY >= 0 && event.deltaY < 0 && scrollUp === false) {
    console.log(event.deltaY, window.scrollY, window.innerHeight)
    scrollUp = true
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
    gsap.to(firefliesMaterial2.uniforms.uDim, {
      value: 0.8,
      duration: 1,
    })
    gsap.to(controls.target, {
      y: 0,
      duration: 1,
    })
    gsap.to(camera.position, {
      y: 0,
      duration: 1,
    })
    gsap.to(backgroundMesh.material.uniforms.uAlt, {
      value: 0,
      duration: 1
    })
    let m = controls.minDistance
    // let n = controls.maxDistance
    controls.minDistance = camera.position.distanceTo(controls.target) - 0.1
    // controls.maxDistance = 1000
    setTimeout(() => {
      controls.minDistance = m
      // controls.maxDistance = n
    }, 2000)
  } else {
  }
}

document.addEventListener('wheel', onMouseWheel, { passive: false })

window.scrollTo({
    top: 0,
    behavior: 'smooth',
})

scrollTopButton.addEventListener('click', () => {
  if (startAnimation) return
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
  gsap.to(firefliesMaterial2.uniforms.uDim, {
    value: 0.8,
    duration: 1,
  })
  gsap.to(controls.target, {
    y: 0,
    duration: 1,
  })
  gsap.to(camera.position, {
    y: 0,
    duration: 1,
  })
  gsap.to(backgroundMesh.material.uniforms.uAlt, {
    value: 0,
    duration: 1
  })
  scrollUp = true
})

// document.body.addEventListener('click', () => {
//     clickSound.play()
// })
loopify("./track.wav",function(err,loop) {

  // If something went wrong, `err` is supplied
  if (err) {
    return console.err(err);
  }

  // Play it whenever you want

  // Stop it later if you feel like it

  soundButton1.addEventListener('click', () => {
    if (
        soundButton1.getAttribute('src') == 'images/Sound_OFF.svg' ||
        soundButton1.getAttribute('src') == 'assets/images/1bad5aaadd9cacf7.svg'
    ) {
      loop.stop();
      soundButton1.src = 'images/Sound_ON.svg'
      soundButton2.src = 'images/Sound_ON.svg'
      changeFireFliesSpeed = false
    } else {
      loop.play();
      soundButton1.src = 'images/Sound_OFF.svg'
      soundButton2.src = 'images/Sound_OFF.svg'
      changeFireFliesSpeed = true
    }
  })

  soundButton2.addEventListener('click', () => {
    if (
        soundButton2.getAttribute('src') == 'images/Sound_OFF.svg' ||
        soundButton2.getAttribute('src') == 'assets/images/1bad5aaadd9cacf7.svg'
    ) {
      loop.stop();
      soundButton1.src = 'images/Sound_ON.svg'
      soundButton2.src = 'images/Sound_ON.svg'
      changeFireFliesSpeed = false
    } else {
      loop.play();
      soundButton1.src = 'images/Sound_OFF.svg'
      soundButton2.src = 'images/Sound_OFF.svg'
      changeFireFliesSpeed = true
    }
  })
});
const loadContainer = document.getElementById('load_container')
const gifContainer = document.getElementById('gif_container')
const advent = document.getElementById('advent')
const sigh = document.getElementById('sigh')
const walk = document.getElementById('walk')
const drag = document.getElementById('drag')

// const exploreText = document.getElementById("explore_text")
// const exploreIcon = document.getElementById("explore_icon")

let playAnimation = true

function startScene() {
  // let clickEventHandler = () => {
  //     window.removeEventListener('click', clickEventHandler)
  if (!finish) {
    finish = true
    startAnimation = true
    clock = new THREE.Clock()
    delta = 0
    // backMusic.play()
    // exploreText.style.display = "none"
    // exploreIcon.style.display = "none"
    soundButton1.src = 'images/Sound_ON.svg'
    soundButton2.src = 'images/Sound_ON.svg'
  }
  start = true
  // }
  // document.addEventListener('click', clickEventHandler)
}

if (playAnimation || process.env.NODE_ENV !== 'development') {
  document.addEventListener('readystatechange', () => {
    if (document.readyState == 'complete') {
      gifContainer.style.display = 'unset'

      setTimeout(() => {
        advent.style.display = 'none'
        walk.style.display = 'unset'
        console.log("dddd", walkAnimation)
        walk.animate(walkAnimation, walkTiming)
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 3000)

      setTimeout(() => {
        walk.style.display = 'none'
        sigh.style.display = 'unset'
        sigh.style.left = hOrW? '39vw': '45vw'

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 4800)

      setTimeout(() => {
        sigh.style.display = 'none'
        walk.style.display = 'unset'
        walk.style.left =  hOrW? '39vw': '45vw'
        walk.animate(walkAnimation2, walkTiming)
      }, 6800)

      setTimeout(() => {
        walk.style.display = 'none'
        drag.style.display = 'unset'
        loadContainer.animate(pageAnimation, pageTiming)
        if (hOrW) {
          drag.style.left = '42%'
        }
        console.log("dncvdfkjn")
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 8600)

      setTimeout(() => {
        startScene()
      }, 8700)

      setTimeout(() => {
        loadContainer.style.display = 'none'
        drag.style.display = 'none'
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 9600)
    }
  })
} else {
  loadContainer.style.display = 'none'
  drag.style.display = 'none'
  startScene()
}

const walkAnimation = [
  {
    transform: 'translateX(0)',
  },
  {
    transform: hOrW? 'translateX(38vw)': 'translateX(43vw)',
  },
]

const walkAnimation2 = [
  {
    transform: 'translateX(0)',
  },
  {
    transform: 'translateX(60vw)',
  },
]

const walkTiming = {
  duration: 1800,
  iterations: 1,
}

const pageAnimation = [
  {
    transform: 'scaleY(1)',
  },
  {
    transform: 'scaleY(0)',
  },
]
const pageTiming = {
  duration: 1000,
  iterations: 1,
}

// social media links

document.getElementById('Logo_Roblox').addEventListener('click', () => {
  window.open('https://www.roblox.com/groups/11013728/TOPCAT-GAMES', '_blank')
})
document.getElementById('Logo_Telegram').addEventListener('click', () => {
  window.open('https://t.me/TOPCATgg', '_blank')
})
document.getElementById('Logo_WhatsApp').addEventListener('click', () => {
  window.open('https://wa.me/message/RDVB6RWFJJ76D1', '_blank')
})
document.getElementById('Logo_Line').addEventListener('click', () => {
  window.open('https://line.me/ti/p/xltq-cL6Nx', '_blank')
})
document.getElementById('Logo_Instagram').addEventListener('click', () => {
  window.open('https://twitter.com/TOPCATgg', '_blank')
})
//
// var context;
// window.addEventListener('load', init, false);
// function init() {
//   try {
//     context = new AudioContext();
//   }
//   catch(e) {
//     alert('Web Audio API is not supported in this browser');
//   }
// }
// function AllowZoom(flag) {
//   if (flag == true) {
//     $('head meta[name=viewport]').remove();
//     $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=10.0, minimum-scale=1, user-scalable=1" />');
//   } else {
//     $('head meta[name=viewport]').remove();
//     $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=0" />');
//   }
// }
// AllowZoom(false);
