import * as THREE from 'three';
import {gsap} from "gsap/all";

export class MovementManager {
    objectOriginPosition = {};
    sceneObjectsProperties = {}
    sceneObjects = {}
    jiggleAnimations = {}
    move = {
        x: 0,
        y: 0,
    }
    pointer;
    radius = 100;

    raycaster = new THREE.Raycaster();
    INTERSECTED;

    constructor() {
    }

    controls
    setGLTF = (gltf, domElement, camera, backgroundMesh, fireflies, controls) => {
        this.controls = controls
        this.gltf = gltf;
        this.domElement = domElement;
        this.backgroundMesh = backgroundMesh;
        this.fireflies = fireflies;
        this.camera = camera;
        this.calculateSceneObjects(this.gltf);
        domElement.addEventListener('pointermove', this.onPointerMove);
    }

    counter = 0;
    interval = 5
    movementManagerClock = 0
    update = (timestamp, delta) => {
        // console.log(this.move);
        this.movementManagerClock += delta;
        if (this.counter++ % this.interval === 0) {
            this.calculateIntersect(this.movementManagerClock);
        }
        this.updateRotation(this.movementManagerClock);
        this.updateJiggleAnimations(this.movementManagerClock);
        this.updatePosition(this.movementManagerClock, delta);
    }

    calculateIntersect(delta) {
        if (!this.pointer || !this.camera) return;
        this.raycaster.setFromCamera(this.pointer, this.camera);
        this.pointer = undefined;
        // console.log(this.scene.children.length);
        const intersects = this.raycaster.intersectObjects(this.scene.children, true);
        if (intersects.length > 0) {
            console.log(intersects.length);
            if (this.INTERSECTED != intersects[0].object) {
                let parent1 = intersects[0].object.parent.name === 'Scene' ? intersects[0].object : intersects[0].object.parent;
                let parent2 = parent1.parent;
                let parent3 = parent2.parent;

                if (!this.jiggleAnimations[parent1.name]) {
                    console.log(this.pointer)
                    this.jiggleAnimations[parent1.name] = {
                        random1: Math.random() * 10 + 5,
                        random2: Math.random() * 10 + 5,
                        random3: Math.random() * 10 + 5,
                        object: parent1,
                        time: delta,
                        phase: 0,
                    };
                }
            }

        } else {
            this.INTERSECTED = undefined;
        }
    }

    onPointerMove = (moveEvent) => {
        if (moveEvent.pointerType === 'touch') {

            this.onTouchMove(moveEvent);

        } else {
            // console.log(this)
            this.onMouseMove(moveEvent);
        }
        if (!this.pointer)
            this.pointer = new THREE.Vector2();
        this.pointer.x = (moveEvent.clientX / window.innerWidth) * 2 - 1;
        this.pointer.y = -(moveEvent.clientY / window.innerHeight) * 2 + 1;

    }

    planetGroup = new THREE.Group();

    calculateSceneObjects = (gltf) => {
        console.log(gltf);
        let g = []
        gltf.scene.children.forEach((child) => {
            console.log(child.name);
            child.visible = false
            child.castShadow = false;
            child.receiveShadow = false;
            this.sceneObjects[child.name] = child;
            if (child.name === 'Planet' || child.name === 'Font001' || child.name === '!' || child.name === 'flash') {
                g.push(child)
            }
            this.sceneObjectsProperties[child.name] = {
                position: new THREE.Vector3(),
                rotation: new THREE.Vector3(),
                scale: 1,
                phase: 0
            }
            this.objectOriginPosition[child.name] = new THREE.Vector3();
            this.objectOriginPosition[child.name].copy(child.position);
        })
        for (const gElement of g) {
            this.planetGroup.add(gElement)
        }
        gltf.scene.add(this.planetGroup)
        console.log(this.sceneObjects);
        this.sceneObjects['Planet'].visible = true;
        // this.sceneObjects['Planet'].rotation.y = elapsedTime / (1 + elapsedTime) * 3.14 * 2;
        this.sceneObjects['Planet'].scale.set(
            1, 1, 1
        )
        this.scene = gltf.scene;
    }

    onMouseMove = (moveEvent) => {
        // console.log(moveEvent.clientX, moveEvent.clientY);
        this.move.x = moveEvent.clientX / window.innerWidth * 2 - 1;
        this.move.y = moveEvent.clientY / window.innerHeight * 2 - 1;
    }

    onTouchMove = (moveEvent) => {
    }

    updateJiggleAnimations(delta) {

        Object.values(this.jiggleAnimations).forEach((animation) => {
            if (animation.phase == 0) {

                animation.object.rotation.y = 1 * Math.sin((delta - animation.time) / (1 + delta - animation.time) * animation.random1) / (3 + (delta - animation.time) * 10);
                animation.object.rotation.z = 1 * Math.sin((delta - animation.time) / (1 + delta - animation.time) * animation.random1) / (3 + (delta - animation.time) * 10);
                let s = 4 * Math.sin((delta - animation.time) / (1 + delta - animation.time) * animation.random1) / ((2 + delta - animation.time) * 20) + 1
                animation.object.scale.y = s;
                animation.object.scale.x = s;
                animation.object.scale.z = s;

                // animation.object.rotation.y +=  Math.sin((delta - animation.time) * animation.random1) / (10 + (delta - animation.time) * 20);
                // animation.object.rotation.z +=  Math.sin((delta - animation.time) * animation.random1) / (10 + (delta - animation.time) * 20);
                // animation.object.rotation.x +=  Math.sin((delta - animation.time) * animation.random3) / 10;

                // console.log(delta - animation.time)
                if ((delta - animation.time) * animation.random1 > 5 * 3.14) {
                    // delete this.jiggleAnimations[animation.object.name];
                    // animation.object.rotation.z = 0
                    // animation.object.scale.y = 1;
                    // animation.object.scale.x = 1;
                    // animation.object.scale.z = 1;
                    animation.phase = 1;
                }
                // animation.time += delta;
            } else {
                gsap.to(animation.object.rotation, {
                    y: 0,
                    z: 0,
                    x: 0,
                    duration: 1,
                })
                gsap.to(animation.object.scale, {
                    y: 1,
                    z: 1,
                    x: 1,
                    duration: 1,
                })
                delete this.jiggleAnimations[animation.object.name];
            }
        })
    }

    rotationSpeed = 0.01;
    rotationCoef = 5;

    updateRotation(delta) {

        Object.values(this.sceneObjects).forEach((object) => {
            // console.log(object.rotation.y, this.move.x / this.rotationCoef)
            if (object.rotation.y - this.move.x / this.rotationCoef > Math.PI) {
                object.rotation.y -= 2 * Math.PI;
            }
            if (this.move.x / this.rotationCoef - object.rotation.y > Math.PI) {
                object.rotation.y += 2 * Math.PI;
            }

            if ((object.rotation.y > this.move.x / this.rotationCoef + 0.01)) {
                object.rotation.y -= this.rotationSpeed;
            }
            if ((object.rotation.y < this.move.x / this.rotationCoef - 0.01)) {
                object.rotation.y += this.rotationSpeed;
            }
            if ((object.rotation.x > this.move.y / this.rotationCoef + 0.01)) {
                object.rotation.x -= this.rotationSpeed;
            }
            if ((object.rotation.x < this.move.y / this.rotationCoef - 0.01)) {
                object.rotation.x += this.rotationSpeed;
            }
        })
    }

    positionCounter = 0;
    textRotationSpeed = 0.5;

    updatePosition(elapsedTime, delta) {
        if (this.sceneObjects['Planet']) {
            this.sceneObjects['Planet'].position.set(
                this.sceneObjects['Planet'].position.x,
                Math.sin(elapsedTime) * 0.1,
                this.sceneObjects['Planet'].position.z
            )
        }
        let speed = 0.06
        if (this.sceneObjects['flash']) {
            this.sceneObjects['flash'].visible = true
            this.sceneObjects['flash'].position.set(
                this.sceneObjects['flash'].position.x,
                Math.sin(elapsedTime) * 0.1,
                this.sceneObjects['flash'].position.z
            )
            if (this.sceneObjects['flash'].scale.x < 1) {
                this.sceneObjects['flash'].scale.x += speed;
                this.sceneObjects['flash'].scale.y += speed;
                this.sceneObjects['flash'].scale.z += speed;
            }
        }
        if (this.sceneObjects['Font001']) {
            this.sceneObjects['Font001'].visible = true
            this.sceneObjects['Font001'].position.set(
                this.sceneObjects['Font001'].position.x,
                Math.sin(elapsedTime) * 0.1,
                this.sceneObjects['Font001'].position.z
            )
            this.textCounter += delta;
            this.sceneObjects['Font001'].rotation.set(
                this.sceneObjects['Font001'].rotation.x,
                -this.textCounter * this.textRotationSpeed,
                this.sceneObjects['Font001'].rotation.z
            )
            if (this.sceneObjects['Font001'].scale.x < 1) {
                this.sceneObjects['Font001'].scale.x += speed;
                this.sceneObjects['Font001'].scale.y += speed;
                this.sceneObjects['Font001'].scale.z += speed;
            }
        }
        if (this.sceneObjects['!']) {
            this.sceneObjects['!'].visible = true
            this.sceneObjects['!'].position.set(
                this.sceneObjects['!'].position.x,
                Math.sin(elapsedTime - 1) * 0.1 + 0.57,
                this.sceneObjects['!'].position.z
            )
            if (this.sceneObjects['!'].scale.x < 1) {
                this.sceneObjects['!'].scale.x += speed;
                this.sceneObjects['!'].scale.y += speed;
                this.sceneObjects['!'].scale.z += speed;
            }
        }

        Object.keys(this.sceneObjects).forEach((key) => {
            if (key === 'Font001' || key === '!' || key === 'Planet' || key === 'flash') return;
            let object = this.sceneObjects[key];
            // let properties = this.sceneObjectsProperties[key];
            object.position.set(
                Math.sin(elapsedTime + object.position.z * 10) * 0.0005 + object.position.x,
                Math.cos(elapsedTime + object.position.z * 10) * 0.0005 + object.position.y,
                object.position.z,
            )
            object.rotation.set(
                object.rotation.x,
                object.rotation.y,
                object.rotation.z + 0.001 * Math.sin(elapsedTime + object.position.z * 10)
            )
        })
    }


    stagesLength = [
        // 0.5,
        // 1,
        2,
        0.5,
        1.6,
        2.5,
        2,
        4,
        5,
    ]
    countert = 0;

    stages = []


    firefliesScale = 1;
    planetRotation = 0;
    objectsScale = 0;
    alreadyAnimated = false;
    objectDisScale = 0;
    objectData = {}
    alreadyAnimatedArray = [false, false, false, false, false, false, false, false, false, false, false];
    objectRotationOffset = 0;

    realElapsedTime = 0;
    maxDis = window.innerWidth/window.innerHeight < 1 ? 11:6

    playAnimation(elapsedTime, delta) {
        this.realElapsedTime += 0.016;
        if (!this.camera) return;
        // console.log(this.realElapsedTime, delta)
        if (!this.alreadyAnimated) {
            this.alreadyAnimated = true;
            this.stagesLength.forEach((length, index) => {
                this.stages.push(this.countert)
                this.countert += length;
            })
            console.log(this.stages)
            gsap.to(this.camera.position, {
                duration: 5,
                x: 0,
                y: 0,
                z: this.maxDis,
                lazy: true,
                ease: "power1.inOut",
            })
            this.fireflies.visible = true;
            // this.fireflies.rotation.y = elapsedTime / (1 + elapsedTime) * 3.14 * 2;
            //
            this.firefliesScale = 1
            this.backgroundMesh.visible = true;
            this.backgroundMesh.material.uniforms.uK.value = 1;
            this.sceneObjects['Planet'].visible = true;
            // this.sceneObjects['Planet'].rotation.y = elapsedTime / (1 + elapsedTime) * 3.14 * 2;
            this.sceneObjects['Planet'].scale.set(
                1, 1, 1
            )
            // this.sceneObjects['Font001'].visible = true;

        }
        let stage = this.findStage(this.realElapsedTime)
        console.log(stage)
        if (stage === 0) { // 4
            if (!this.alreadyAnimatedArray[stage]) {
                this.alreadyAnimatedArray[stage] = true;
                gsap.to(this, {
                    firefliesScale: 0.3,
                    duration: 3,
                    lazy: true,
                })
                gsap.to(this, {
                    planetRotation: Math.PI / 10,
                    duration: 4,
                    lazy: true,
                })
            }
            this.fireflies.material.uniforms.uPositionScale.value = this.firefliesScale * this.firefliesScale * this.firefliesScale;
            this.planetGroup.rotation.y += this.planetRotation;
            this.fireflies.rotation.y += this.planetRotation / 5;
        } else if
        (stage === 1) { // 2
            if (!this.alreadyAnimatedArray[stage]) {
                this.alreadyAnimatedArray[stage] = true;
                gsap.to(this.planetGroup.scale, {
                    x: 0.2,
                    y: 0.2,
                    z: 0.2,
                    duration: 0.2,
                    delay: 0.3,
                    lazy: true,
                })
                this.sceneObjects['Font001'].visible = true;
                this.sceneObjects['flash'].visible = true;
                this.sceneObjects['!'].visible = true;
                console.log("fireflies", this.firefliesScale)
                gsap.to(this, {
                    firefliesScale: 0.2,
                    duration: 0.2,
                    delay: 0.3,
                    lazy: true,
                })

            }
            this.fireflies.material.uniforms.uPositionScale.value = this.firefliesScale * this.firefliesScale * this.firefliesScale;
            this.planetGroup.rotation.y += this.planetRotation;
            this.fireflies.rotation.y += this.planetRotation / 5;
        } else if (stage === 2) { // 4
            if (!this.alreadyAnimatedArray[stage]) {
                this.alreadyAnimatedArray[stage] = true;
                Object.keys(this.sceneObjects).forEach((key) => {
                    if (key === 'Font001' || key === '!' || key === 'Planet' || key === 'flash') return;
                    let object = this.sceneObjects[key];
                    // let properties = this.sceneObjectsProperties[key];
                    object.visible = true;
                    object.scale.set(
                        0,
                        0,
                        0
                    )
                    let positionX = object.position.x;
                    let positionY = object.position.y;
                    let positionZ = object.position.z;
                    this.objectData[object.name] = {
                        positionX,
                        positionY,
                        positionZ,
                    }
                    object.position.set(
                        0,
                        0,
                        0
                    )
                    gsap.to(object.scale, {
                        x: 1,
                        y: 1,
                        z: 1,
                        duration: 2,
                        lazy: true,
                    })
                })
                gsap.to(this, {
                    objectsScale: 1,
                    duration: 2,
                })
                gsap.to(this, {
                    planetRotation: 0,
                    duration: 2
                })
                gsap.to(this, {
                    firefliesScale: 0.8,
                    duration: 3
                })
                this.cameraPosition = this.camera.position.clone();
                gsap.to(this.camera.rotation, {
                    y: -2 * Math.PI,
                    duration: 4,
                    lazy: true,
                    ease: "power1.out",
                })
                gsap.to(this.planetGroup.scale, {
                    x: 9,
                    y: 9,
                    z: 9,
                    duration: 0.2,
                    lazy: true,
                    // ease : 'power4.out',
                })
                gsap.to(this.planetGroup.scale, {
                    x: 1,
                    y: 1,
                    z: 1,
                    duration: 1,
                    lazy: true,
                    ease: (input) => {
                        console.log("innnn", input)
                        return input
                    },
                    delay: 0.15,
                })
                gsap.to(this.cameraPosition, {
                    duration: 4,
                    x: 0,
                    y: 0,
                    z: this.maxDis,
                    lazy: true,
                })
            }
            this.objectRotationOffset += this.planetRotation / 2;

            Object.keys(this.sceneObjects).forEach((key) => {
                if (key === 'Font001' || key === '!' || key === 'Planet' || key === 'flash') return;
                let object = this.sceneObjects[key];
                object.position.set(
                    (this.objectData[object.name].positionX * Math.sin(this.objectRotationOffset) + this.objectData[object.name].positionZ * Math.cos(this.objectRotationOffset) * -1) * this.objectsScale,
                    this.objectData[object.name].positionY * this.objectsScale,
                    (this.objectData[object.name].positionZ * Math.sin(this.objectRotationOffset) + this.objectData[object.name].positionX * Math.cos(this.objectRotationOffset)) * this.objectsScale,
                )
            })
            // console.log(this.camera.rotation.y, Math.sin(this.camera.rotation.y), Math.cos(this.camera.rotation.y))
            this.camera.position.set(
                this.cameraPosition.x * Math.cos(this.camera.rotation.y) + this.cameraPosition.z * Math.sin(this.camera.rotation.y),
                this.cameraPosition.y,
                this.cameraPosition.z * Math.cos(this.camera.rotation.y) + this.cameraPosition.x * Math.sin(this.camera.rotation.y) * -1,
            )
            this.planetGroup.rotation.y += this.planetRotation;
            this.fireflies.rotation.y += this.planetRotation / 10;
            this.fireflies.material.uniforms.uPositionScale.value = this.firefliesScale * this.firefliesScale * this.firefliesScale;
        } else if (stage === 3) { // 4
            if (!this.alreadyAnimatedArray[stage]) {
                this.alreadyAnimatedArray[stage] = true;
                this.planetGroup.rotation.y = this.planetGroup.rotation.y % (Math.PI * 2);
                this.fireflies.rotation.y = this.fireflies.rotation.y % (Math.PI * 2);
                gsap.to(this.planetGroup.rotation, {
                    y: this.planetGroup.rotation.y > Math.PI ? 2 * Math.PI : 0,
                    duration: 2,
                    lazy: true,
                })

                // gsap.to(this.sceneObjects['Planet'].scale, {
                //     x: 1,
                //     y: 1,
                //     z: 1,
                //     duration: 3,
                //     lazy: true,
                //     ease : 'elastic.out',
                // })
                gsap.to(this.fireflies.rotation, {
                    y: this.fireflies.rotation.y > Math.PI ? 2 * Math.PI : 0,
                    duration: 2,
                    lazy: true,
                })
                Object.keys(this.sceneObjects).forEach((key) => {
                    if (key === 'Font001' || key === '!' || key === 'Planet' || key === 'flash') return;
                    let object = this.sceneObjects[key];
                    gsap.to(object.position, {
                        x: this.objectData[object.name].positionX,
                        y: this.objectData[object.name].positionY,
                        z: this.objectData[object.name].positionZ,
                        duration: 2,
                        lazy: true,
                    })
                })
            }
            // console.log(this.camera.rotation.y, Math.sin(this.camera.rotation.y), Math.cos(this.camera.rotation.y))
            this.camera.position.set(
                this.cameraPosition.x * Math.cos(this.camera.rotation.y) + this.cameraPosition.z * Math.sin(this.camera.rotation.y),
                this.cameraPosition.y /*+ Math.cos(this.camera.rotation.y)*/,
                this.cameraPosition.z * Math.cos(this.camera.rotation.y) + this.cameraPosition.x * Math.sin(this.camera.rotation.y) * -1,
            )
            this.camera.lookAt(0, 0, 0);
        } else if (stage === 4) {
            if (!this.alreadyAnimatedArray[stage]) {
                // gsap.to(this.sceneObjects['Planet'].scale, {
                //     x: 1,
                //     y: 1,
                //     z: 1,
                //     duration: 0.5,
                //     lazy: true,
                //     yoyoEase: true,
                // })
            }
            return false;
        } else if (stage === 5) {
            return false;

        }
        return true
    }

    findStage(elapsedTime) {
        let stage = 0;
        this.stages.forEach((stageTime, index) => {
            if (elapsedTime > stageTime) {
                stage = index;
            }
        })
        return stage;
    }

    textCounter = 0;

    updateBefore(elapsedTime, delta) {
        this.textCounter += delta;
        if (this.sceneObjects && this.sceneObjects['Font001'] && this.sceneObjects['Font001'].visible) {
            this.sceneObjects['Font001'].rotation.set(
                this.sceneObjects['Font001'].rotation.x,
                -this.textCounter * this.textRotationSpeed,
                this.sceneObjects['Font001'].rotation.z
            )
        }
    }
}
